import { CompanyAPI } from "@/apis";
import { Button, Form, Input, Layout, Message, Space, Typography } from "@arco-design/web-react"
import { Header } from '@/components/Header';
import { useState } from "react";
import { useNavigate } from "react-router-dom";
const FormItem = Form.Item;
const { Title } = Typography;

export const Register = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const onSubmit = async () => {
        let values = await form.validate();
        setLoading(true)
        try {
            await CompanyAPI.register(values);
            Message.success('Register successfully');
            navigate('/login');
        } catch (error) {
            Message.error(error);
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className="container">
            <Layout>
                <Layout.Header>
                    <Header />
                </Layout.Header>
                <Layout.Content >
                    <div style={{ width: 450, margin: "85px auto" }}>
                        <Title heading={3}>Create New Account</Title>
                        <Form
                            className='login-form'
                            layout="vertical"
                            form={form}
                        >
                            <FormItem
                                label="Company Name"
                                field="companyName"
                                rules={[{ required: true }]}
                            >
                                <Input />
                            </FormItem>
                            <FormItem style={{ marginBottom: 0 }}
                                label="Contact Name"
                            >
                                <Space>
                                    <FormItem field="firstName" style={{ width: 220 }}>
                                        <Input placeholder="First Name" />
                                    </FormItem>
                                    <FormItem field="lastName" style={{ width: 220 }}>
                                        <Input placeholder="Last Name" />
                                    </FormItem>
                                </Space>
                            </FormItem>
                            <FormItem
                                label="Email: This will be your user name and initial password. You can change your passowrd after you login."
                                field="email"
                                rules={[{ required: true, message: "Email is required" }, { type: "email" }]}
                            >
                                <Input />
                            </FormItem>
                            <FormItem
                                label="Phone"
                                field="phone"
                                rules={[{ required: true }]}
                            >
                                <Input />
                            </FormItem>
                            <FormItem
                                label="Business Industry"
                                field="businessIndustry"
                                rules={[{ required: true }]}
                            >
                                <Input />
                            </FormItem>
                            <FormItem>
                                <Button type="primary" long onClick={onSubmit} loading={loading}>
                                    Register
                                </Button>
                            </FormItem>
                        </Form>
                    </div>
                </Layout.Content>
            </Layout>
        </div>
    )
}