import { Button, Card, Input, Message, Modal, Space, Upload, Table } from "@arco-design/web-react";
import { IconEdit } from "@arco-design/web-react/icon";
import { useCallback, useEffect, useRef, useState } from "react";
import { ItemAPI, AccountAPI } from "@/apis";
import Status from "@/components/Status";
import { ItemForm } from "./item-form";
import ItemDocButton from "./item-doc";
import { baseUrl } from "@/utils/api";
import { ItemLink } from "@/components/ItemLink";
import { TablePaginationConfig } from "@/components/UI/config";

const AddItemButton = ({ children, onSuccess }) => {
    const [visible, setVisible] = useState(false);
    const [item, setItem] = useState();
    const formRef = useRef();

    const submit = async () => {
        const values = await formRef.current.getValues();
        try {
            const id = await ItemAPI.add(values);
            Message.success("Item created successfully");
            setVisible(false);
            onSuccess && onSuccess(id);
        } catch (error) {
            Message.error(error.message);
        }
    }

    const setDefaultAccounts = async () => {
        const defaultItem = { itemType: 1 };
        const cogsAccount = await AccountAPI.getByName("Cost of Goods Sold");
        const incomeAccount = await AccountAPI.getByName("Revenue");
        const inventoryAccount = await AccountAPI.getByName("Inventory");
        defaultItem.cogsAccountId = cogsAccount.id;
        defaultItem.incomeAccountId = incomeAccount.id;
        defaultItem.inventoryAccountId = inventoryAccount.id;
        setItem(defaultItem);
    }

    return (
        <>
            <Button type="primary" onClick={async () => {
                await setDefaultAccounts();
                setVisible(true);
            }}>{children}</Button>
            <Modal title="Add New Item" visible={visible} style={{ width: 650 }}
                onOk={() => submit()}
                onCancel={() => {
                    setVisible(false);
                }}>
                <ItemForm ref={formRef} value={item} />
            </Modal>
        </>
    )
}

const EditItemButton = ({ itemId, children, onSuccess }) => {
    const [visible, setVisible] = useState(false);
    const [item, setItem] = useState();
    const formRef = useRef();

    const getItem = async () => {
        const values = await ItemAPI.get(itemId);
        setItem(values);
    }

    const submit = async () => {
        const values = await formRef.current.getValues();
        try {
            await ItemAPI.update(itemId, values);
            Message.success("Item updated successfully");
            setVisible(false);
            onSuccess && onSuccess();
        } catch (error) {
            Message.error(error.message);
        }
    }

    return (
        <>
            <Button type="text" icon={<IconEdit />} size="small" onClick={async () => {
                await getItem();
                setVisible(true);
            }}>{children}</Button>
            <Modal title="Item" style={{ width: 650 }} visible={visible}
                onOk={() => submit()}
                onCancel={() => {
                    setVisible(false)
                }}
            >
                <ItemForm ref={formRef} value={item} />
            </Modal>

        </>
    )
}

export const Items = () => {
    const [page, setPage] = useState(1);
    const [itemName, setItemName] = useState();
    const [status, setStatus] = useState(1);
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState();

    const getItems = useCallback(async () => {
        const params = {
            page: page,
            pageSize: TablePaginationConfig.pageSize,
            name: itemName,
            status: status
        }
        const res = await ItemAPI.query(params);
        setData(res.records);
        setPagination((pagination) => ({
            ...pagination,
            current: page,
            total: res.total
        }));
    }, [itemName, status, page]);

    const onItemUpload = (fileList, file) => {
        if (file.status === "done") {
            Message.success("Upload Successfully");
            getItems();
        } else if (file.status === "error") {
            console.log(file.response);
            Message.error(`${file.response.message}`)
        }
    }

    useEffect(() => {
        getItems();
    }, [getItems]);

    return (
        <Card title={
            <Space>
                <Input placeholder="Item Name" onChange={(val) => {
                    setItemName(val);
                }} />
                <Status.Select style={{ width: 100 }} allowAll onChange={(val) => {
                    setStatus(val);
                }} />
            </Space>
        } extra={
            <Space >
                <AddItemButton onSuccess={() => getItems()}>Add New Item</AddItemButton>
                <Upload withCredentials
                    headers={{ 'falcontoken': localStorage.getItem("login.token") }}
                    action={`${baseUrl}/item/import`}
                    onChange={onItemUpload}
                    showUploadList={false}>
                    <Button type="primary">Import Items</Button>
                </Upload>
                <Button type="primary" onClick={() => window.location = `${baseUrl}/ItemTemplate.xlsx`}>Download Template</Button>
            </Space>
        }>
            <Table columns={[
                { title: "Item Type", dataIndex: "itemTypeDesc" },
                {
                    title: "Item Number",
                    render: (col, record) => <ItemLink value={record.id}>{record.itemNo}</ItemLink>
                },
                { title: "Item Name", dataIndex: "itemName" },
                {
                    title: "Status", dataIndex: "status",
                    render: (col, record) =>
                        <Status.Tag value={record.status}>{record.statusDesc}</Status.Tag>

                },
                { title: "Description", dataIndex: "description" },
                {
                    title: "Operations", dataIndex: "op",
                    width: 100,
                    render: (col, record) => <Space size="mini">
                        <ItemDocButton itemId={record.id} />
                        <EditItemButton itemId={record.id} onSuccess={() => getItems()}>Edit</EditItemButton>
                    </Space>

                }
            ]} rowKey="id"
                data={data}
                pagination={pagination}
                onChange={(pagination) => setPage(pagination.current)}>
            </Table>
        </Card>
    );
}